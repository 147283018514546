import { FC } from 'react';
import { Typography } from '@mui/material';

import NextLinkWithNoCss from '@/components/NextLinkWithNoCss/NextLinkWithNoCss';

import useStyles from './styles';

export type BreadcrumbItemProps = {
  content: string;
  label: string;
  isLastElement?: boolean;
  href?: string;
};

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({
  href = '',
  content,
  isLastElement = false,
  label,
}) => {
  const { classes, cx } = useStyles({ isLastElement });
  return (
    <li
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/ListItem"
      className={cx(classes.item, classes.list)}
    >
      {isLastElement ? (
        <Typography
          itemProp="name"
          component="span"
          variant="bodySmBold"
          className={classes.item}
        >
          {label}
        </Typography>
      ) : (
        <NextLinkWithNoCss href={href} itemProp="item">
          <Typography
            itemProp="name"
            component="span"
            variant="bodySmBold"
            className={classes.item}
          >
            {label}
          </Typography>
        </NextLinkWithNoCss>
      )}
      <meta itemProp="position" content={content} />
    </li>
  );
};
export default BreadcrumbItem;
