import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette: { primary, neutral } }) => ({
  subtitle: {
    color: primary[400],
  },
  description: {
    marginTop: rem(12),
    marginBottom: rem(28),
    color: neutral[200],
  },
}));
