import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ isLastElement: boolean }>()(
  ({ palette }, { isLastElement }) => ({
    item: {
      color: palette.common.white,
    },
    list: {
      '::after': {
        marginLeft: !isLastElement && rem(13),
        content:
          !isLastElement &&
          `url(
          "data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M0.835877 7.76569C0.523458 7.45327 0.523458 6.94673 0.835877 6.63432L3.47019 4L0.835877 1.36569C0.523458 1.05327 0.523458 0.546734 0.835877 0.234315C1.1483 -0.078105 1.65483 -0.078105 1.96725 0.234315L5.16725 3.43431C5.47967 3.74673 5.47967 4.25327 5.16725 4.56569L1.96725 7.76569C1.65483 8.0781 1.1483 8.0781 0.835877 7.76569Z' fill='%23D4D4D8' /%3E%3C/svg%3E"
        )`,
      },
    },
  })
);
