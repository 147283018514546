import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  seoParagraphContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: `0 ${rem(16)} ${rem(80)}`,
    [breakpoints.up('md')]: {
      padding: `0 ${rem(20)} ${rem(80)}`,
    },
    [breakpoints.up('lg')]: {
      padding: `0 ${rem(80)} ${rem(80)}`,
    },
  },
  paragraphContent: {
    columns: '1',
    columnGap: rem(80),
    width: '100%',
    [breakpoints.up('md')]: {
      columns: '2',
    },
  },
  paragaphContainer: {
    width: '100%',
    display: 'grid',
    maxWidth: rem(1440),
  },
  titleContent: {
    textAlign: 'center',
    width: ' 100%',
    maxWidth: rem(1440),
    margin: `${rem(40)} 0 ${rem(16)}`,
    [breakpoints.up('md')]: {
      margin: `${rem(60)} 0 ${rem(20)}`,
    },
    [breakpoints.up('lg')]: {
      margin: `${rem(80)} 0 ${rem(60)}`,
    },
  },
}));
