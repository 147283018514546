import { FC, PropsWithChildren, ReactElement } from 'react';
import { SeoParagraph } from '@les-sherpas/sherpas-toolbox';
import { Typography } from '@mui/material';

import SEOParagraphSection from '@/components/Landing/Seo/SEOParagraph/SEOParagraphSection';

import useStyles from './styles';

type Props = {
  sectionData: SeoParagraph[];
  title: string | ReactElement;
};

const LandingSEOParagraphs: FC<PropsWithChildren<Props>> = ({
  sectionData,
  title,
}) => {
  const { classes } = useStyles();

  return (
    <article className={classes.seoParagraphContainer}>
      <Typography
        variant="titleLg"
        component="h2"
        className={classes.titleContent}
      >
        {title}
      </Typography>
      <div className={classes.paragaphContainer}>
        <div className={classes.paragraphContent}>
          {sectionData?.map(({ title: SEOTitle, text }, i) => (
            <SEOParagraphSection subtitle={SEOTitle} content={text} key={i} />
          ))}
        </div>
      </div>
    </article>
  );
};

export default LandingSEOParagraphs;
