import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import SectionDescription from '@/components/Landing/Seo/SectionDescription/SectionDescription';
import { SEODefaultChunks } from '@/generic/SEOFormattedMessage/SEOFormattedMessage';
import LinkParsing from '@/utils/HTMLparsing';

type Props = {
  content: string;
  subtitle: string;
};

const SEOParagraphSection: FC<
  PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>
> = ({ content, subtitle }) => {
  const { content: formatted, links } = LinkParsing(content);

  const formatter = {
    ...SEODefaultChunks,
    ...links,
  };

  return (
    <SectionDescription
      subtitle={subtitle}
      content={
        <FormattedMessage
          id="landing.teacher.seo.paragraph.subtitle"
          defaultMessage={formatted}
          values={formatter}
        />
      }
    />
  );
};

export default SEOParagraphSection;
