function addressGenerator({
  city,
  postalCode,
  level,
}: {
  city: string;
  level: string;
  postalCode?: string;
}) {
  if (city && !level)
    return {
      '@type': 'PostalAddress',
      addressLocality: city,
      postalCode,
      addressCountry: {
        '@type': 'Country',
        name: 'France',
      },
    };
  return {
    '@type': 'PostalAddress',
    streetAddress: '89 avenue Paul Doumer',
    addressLocality: 'Paris',
    addressRegion: 'Île-de-France',
    postalCode: '75116',
    addressCountry: {
      '@type': 'Country',
      name: 'France',
    },
  };
}

function geoGenerator({
  latitude,
  longitude,
  city,
  level,
}: {
  latitude?: number;
  longitude?: number;
  city?: string;
  level?: string;
}) {
  if (city && !level) {
    return {
      '@type': 'GeoCoordinates',
      latitude: latitude.toString(),
      longitude: longitude.toString(),
    };
  }
  return {
    '@type': 'GeoCoordinates',
    latitude: '46.227638',
    longitude: '2.213749',
    addressCountry: {
      '@type': 'Country',
      name: 'France',
    },
  };
}

function getName({
  subject,
  city,
  isOnline,
  level,
}: {
  subject: string;
  city: string;
  isOnline: boolean;
  level: string;
}) {
  if (isOnline) return `${subject} en ligne`;
  if (subject && city) return `${subject} ${city}`;
  if (level) return `${level}`;
  return subject || city;
}

function getItemListElement({
  level,
  canonical,
  itemOfferedName,
}: {
  level: string;
  canonical: string;
  itemOfferedName: string;
}) {
  if (level) {
    return [
      {
        '@type': 'Offer',
        itemOffered: {
          '@type': 'Service',
          name: itemOfferedName,
          url: canonical,
        },
      },
      {
        '@type': 'Offer',
        itemOffered: {
          '@type': 'Service',
          name: 'Soutien scolaire',
          url: 'https://sherpas.com/cours/soutien-scolaire',
        },
      },
      {
        '@type': 'Offer',
        itemOffered: {
          '@type': 'Service',
          name: 'Aide aux devoirs',
          url: 'https://sherpas.com/cours/aide-aux-devoirs',
        },
      },
    ];
  }

  return [
    {
      '@type': 'Offer',
      itemOffered: {
        '@type': 'Service',
        name: itemOfferedName,
        url: canonical,
      },
    },
  ];
}

export function getStructuredGiveCourseData() {
  return {
    '@context': 'https://schema.org/',
    '@type': 'LocalBusiness',
    url: 'https://sherpas.com/donner-cours-particuliers',
    name: 'Donner Cours particuliers',
    brand: 'Les Sherpas',
    legalName: 'Les Sherpas',
    slogan: 'Donne des cours particuliers dans plus de 500 matières !',
    description:
      'Trouvez le prof idéal pour vous accompagner ✓ Excellence académique (HEC, X, Sciences Po..) +500 matières, tous niveaux ✓ 1er cours offert ✓ Dès 10€/h',
    image: 'https://sherpas.com/p/files/cours-particuliers.webp',
    logo: 'https://sherpas.com/images/icons/SherpasDeskop.svg',
    sameAs: [
      'https://www.facebook.com/lessherpas/',
      'https://www.instagram.com/les_sherpas/',
      'https://www.youtube.com/c/LesSherpas',
      'https://www.tiktok.com/@les_sherpas',
      'https://fr.linkedin.com/company/les-sherpas',
    ],
    openingHours: ['Mo-Sa 10:00-19:00'],
    currenciesAccepted: 'EUR',
    paymentAccepted: 'Carte de crédit, virement, chèque',
    priceRange: '$$',
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: 'Donner Cours particuliers à domicile ou en ligne',
      itemListElement: [
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: "Donner des cours particuliers d'Allemand",
            url: 'https://sherpas.com/donner-cours-particuliers/allemand',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: "Donner des cours particuliers d'Anglais",
            url: 'https://sherpas.com/donner-cours-particuliers/anglais',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de Biologie',
            url: 'https://sherpas.com/donner-cours-particuliers/biologie',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de Chimie',
            url: 'https://sherpas.com/donner-cours-particuliers/chimie',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de Droit',
            url: 'https://sherpas.com/donner-cours-particuliers/droit',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: "Donner des cours particuliers d'économie",
            url: 'https://sherpas.com/donner-cours-particuliers/economie',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: "Donner des cours particuliers d'Espagnol",
            url: 'https://sherpas.com/donner-cours-particuliers/espagnol',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de Français',
            url: 'https://sherpas.com/donner-cours-particuliers/francais',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: "Donner des cours particuliers d'Histoire",
            url: 'https://sherpas.com/donner-cours-particuliers/histoire',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: "Donner des cours particuliers d'Informatique",
            url: 'https://sherpas.com/donner-cours-particuliers/informatique',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: "Donner des cours particuliers d'Italien",
            url: 'https://sherpas.com/donner-cours-particuliers/italien',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de Langues',
            url: 'https://sherpas.com/donner-cours-particuliers/langues',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de Maths',
            url: 'https://sherpas.com/donner-cours-particuliers/maths',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de Médecine',
            url: 'https://sherpas.com/donner-cours-particuliers/medecine',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: "Donner des cours particuliers d'Orthographe",
            url: 'https://sherpas.com/donner-cours-particuliers/orthographe',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de Philosophie',
            url: 'https://sherpas.com/donner-cours-particuliers/philosophie',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de Physique',
            url: 'https://sherpas.com/donner-cours-particuliers/physique',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de SES',
            url: 'https://sherpas.com/donner-cours-particuliers/ses',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de Soutien Scolaire',
            url: 'https://sherpas.com/donner-cours-particuliers/soutien-scolaire',
          },
        },
        {
          '@type': 'Offer',
          itemOffered: {
            '@type': 'Service',
            name: 'Donner des cours particuliers de SVT',
            url: 'https://sherpas.com/donner-cours-particuliers/svt',
          },
        },
      ],
    },
    address: {
      '@type': 'PostalAddress',
      streetAddress: '89 avenue Paul Doumer',
      addressLocality: 'Paris',
      addressRegion: 'Île-de-France',
      postalCode: '75116',
      addressCountry: { '@type': 'Country', name: 'France' },
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: '46.227638',
      longitude: '2.213749',
      addressCountry: { '@type': 'Country', name: 'France' },
    },
    telephone: '+33176421408',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.8',
      bestRating: '5',
      worstRating: '1',
      reviewCount: '38',
    },
  };
}

function getStructuredData({
  subject,
  city,
  level,
  h1Content,
  metaDescription,
  canonical,
  latitude,
  longitude,
  postalCode,
  isOnline,
  page = 'other',
}: {
  subject: string;
  city: string;
  level: string;
  h1Content: string;
  metaDescription: string;
  canonical: string;
  latitude?: number;
  longitude?: number;
  postalCode?: string;
  isOnline?: boolean;
  page?: 'donner-cours-particuliers' | 'other';
}) {
  let name;
  let hasOfferCatalogName;
  let itemOfferedName;
  switch (page) {
    case 'donner-cours-particuliers':
      name = `Donner des cours particuliers ${getName({
        city,
        subject,
        isOnline,
        level,
      })} - Les Sherpas`;
      hasOfferCatalogName = `Donner des cours particuliers${
        isOnline ? ' en ligne' : ''
      }`;
      itemOfferedName = `Donner des cours particuliers ${getName({
        city,
        subject,
        isOnline,
        level,
      })}`;
      break;
    default:
      name = `Cours particuliers ${getName({
        city,
        subject,
        isOnline,
        level,
      })} - Les Sherpas`;
      hasOfferCatalogName = `Cours particuliers${isOnline ? ' en ligne' : ''}`;
      itemOfferedName = `Cours particuliers ${getName({
        city,
        subject,
        isOnline,
        level,
      })}`;
  }

  return {
    '@context': 'https://schema.org/',
    '@type': 'LocalBusiness',
    url: canonical,
    name,
    brand: 'Les Sherpas',
    legalName: 'Les Sherpas',
    slogan: h1Content,
    description: metaDescription,
    image: 'https://sherpas.com/p/files/cours-particuliers.webp',
    logo: 'https://sherpas.com/images/icons/SherpasDeskop.svg',
    sameAs: [
      'https://www.facebook.com/lessherpas/',
      'https://www.instagram.com/les_sherpas/',
      'https://www.youtube.com/c/LesSherpas',
      'https://www.tiktok.com/@les_sherpas',
      'https://fr.linkedin.com/company/les-sherpas',
    ],
    openingHours: ['Mo-Sa 10:00-19:00'],
    currenciesAccepted: 'EUR',
    paymentAccepted: 'Carte de crédit, virement, chèque',
    priceRange: 'à partir de 15€/h',
    hasOfferCatalog: {
      '@type': 'OfferCatalog',
      name: hasOfferCatalogName,
      itemListElement: getItemListElement({
        level,
        canonical,
        itemOfferedName,
      }),
    },
    address: addressGenerator({ city, postalCode, level }),
    geo: geoGenerator({ latitude, longitude, city, level }),
    telephone: '+33176421408',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.8',
      bestRating: '5',
      worstRating: '1',
      reviewCount: '38',
    },
  };
}

export default getStructuredData;
