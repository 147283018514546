import { FC, ReactNode } from 'react';

import BreadcrumbItem, {
  BreadcrumbItemProps,
} from '@/Molecules/Breadcrumb/BreadcrumbItem/BreadcrumbItem';

import useStyles from './styles';

type Compound = {
  Item: FC<BreadcrumbItemProps>;
};

export type BreadcrumbProps = FC<{
  children: ReactNode;
}> &
  Compound;

const Breadcrumb: BreadcrumbProps = ({ children }) => {
  const { classes } = useStyles();
  return (
    <nav aria-label="breadcrumb" className={classes.breadcrumb}>
      <ol
        itemScope
        itemType="https://schema.org/BreadcrumbList"
        className={classes.list}
      >
        {children}
      </ol>
    </nav>
  );
};

Breadcrumb.Item = BreadcrumbItem;

export default Breadcrumb;
