import { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Typography } from '@mui/material';

import useStyles from './styles';

type Props = {
  subtitle: string | ReactElement;
  content: string | ReactElement | ReactNode;
};

const SectionDescription: FC<PropsWithChildren<Props>> = ({
  subtitle,
  content,
}) => {
  const { classes } = useStyles();
  return (
    <>
      {subtitle && (
        <Typography
          variant="titleXs"
          component="h3"
          className={classes.subtitle}
        >
          {subtitle}
        </Typography>
      )}
      {content && (
        <Typography
          variant="bodyLg"
          component="p"
          className={classes.description}
        >
          {content}
        </Typography>
      )}
    </>
  );
};

export default SectionDescription;
